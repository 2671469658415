.settingsAccordion {
    margin: 10px 0;
    transition: max-height 0.3s ease;
    overflow: hidden;
  }
  
  .accordion {
    padding: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
  }
  
  .accordion:hover {
    background-color: #e0e0e0;
  }
  
  .accordionDetails {
    padding: 10px;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
  }
  
  .accordionDetails.hide {
    max-height: 0;
    opacity: 0;
  }
  
  .accordionDetails.show {
    opacity: 1;
  }
  